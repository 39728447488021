<script setup>
import { useCollectionStore } from "~/store/useCollectionStore";
import { storeToRefs } from "pinia";

const collectionStore = useCollectionStore();
const { isSidebarExpanded } = storeToRefs(collectionStore);
const { toggleSidebar } = collectionStore;
</script>

<template>
  <button
    @click="toggleSidebar"
    class="bg-[#f9f9f9] border-2 border-white rounded-full absolute left-0 flex items-center justify-center h-7 w-7 transition hover:scale-125"
  >
    <i class="pi" :class="isSidebarExpanded ? 'pi-angle-left' : 'pi-angle-right'"> </i>
  </button>
</template>

<style></style>
