<template>
  <div class="text-center select-none lg:w-full">
    <SvgoNoLinkFound class="lg:mx-auto text-[24rem] pointer-events-none" />
    <h1 class="font-bold text-xl dark:text-gray-50">No collection found.</h1>
  </div>
</template>

<script setup></script>

<style></style>
