<script setup>
import { storeToRefs } from "pinia";
import { useCollectionStore } from "~/store/useCollectionStore.js";

const { isSidebarExpanded } = storeToRefs(useCollectionStore());
</script>

<template>
  <div
    class="w-[40%] lg:w-[30%] bg-[#f9f9f9] dark:bg-[#1f1f1f] overflow-hidden h-full rounded-2xl z-10"
    :class="{ '!w-[0.9%]': !isSidebarExpanded }"
  >
    <CollectionsTable />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<style scoped></style>

<style></style>
