<script setup>
import { useConfirm } from "primevue/useconfirm";
import { useCollectionsTableContextMenu } from "~/composables/collections-table/useCollectionsTableContextMenu.js";

defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const confirm = useConfirm();

const refreshCollectionsTable = inject("refreshCollectionsTable");

// Context Menu
const {
  contextMenuItems,
  cmSelectedRow,
  contextMenuRef,
  onRowContextMenu,
} = useCollectionsTableContextMenu({
  confirm,
  refreshCollectionsTable,
});

//
</script>
<template>
  <button class="relative w-full">
    <i v-if="data.is_pinned" class="absolute right-0"><IconPin /></i>
    <div
      class="flex justify-between items-stretch collection-body-div w-full py-[0.40rem] px-3 rounded-xl"
      :class="[
        {
          '!bg-yellow-500 !text-slate-50': data.is_premium,
        },
        {
          '!text-primary ': data.id == route.params.id,
        },
      ]"
    >
      <div
        class="w-full flex items-center"
        :class="[
          { flex: data.is_premium },
          {
            '!text-primary ': data.id == route.params.id,
          },
        ]"
      >
        <SvgCrown
          v-if="data.is_premium"
          :height="20"
          :width="20"
          :additionalClass="[
            'mr-2',
            {
              '!text-primary !font-bold': data.id == route.params.id,
            },
          ]"
        />
        <span
          class="truncate w-full text-left select-none font-medium collection-name block"
          :class="{ '!font-bold': data.id == route.params.id }"
        >
          {{ data.name }}</span
        >
        <!-- <div v-if="data?.description?.length" class=" ">
          <span class="text-gray-500 font-normal text-sm">
            {{ data.description }}
          </span>
        </div> -->
      </div>

      <div class="items-center flex">
        <Button
          id="collection-actions-id"
          type="button"
          class="p-button-rounded p-button-text !text-primary"
          icon="pi pi-ellipsis-v"
          :iconClass="data.is_premium ? '!text-slate-50' : ''"
          @click="onRowContextMenu($event, data)"
          aria-haspopup="true"
          aria-controls="collection_action_menu"
        />
      </div>
    </div>
  </button>
  <Menu
    v-model="cmSelectedRow"
    id="collection_action_menu"
    ref="contextMenuRef"
    :model="contextMenuItems"
    :popup="true"
  />
</template>

<style></style>
