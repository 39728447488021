<script setup>
import { storeToRefs } from "pinia";
import { useCollectionStore } from "~/store/useCollectionStore.js";
import BaseLayout from "./base.vue";

const { isSidebarExpanded } = storeToRefs(useCollectionStore());
</script>

<template>
  <BaseLayout>
    <div class="h-full flex gap-3">
      <CollectionSidebar />
      <div
        class="w-[60%] lg:w-[70%] h-full rounded-2xl"
        :class="[{ '!w-[99%]': !isSidebarExpanded }]"
      >
        <CollectionMainContent>
          <slot />
        </CollectionMainContent>
      </div>
    </div>
  </BaseLayout>
</template>
