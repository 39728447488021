import { useCollectionStore } from "~/store/useCollectionStore.js";
import { notifyError } from "~/utils/notifications.js";

export const useCollectionsTable = ({ data }) => {
  const collectionTableRef = ref();
  const route = useRoute();
  const router = useRouter();

  const { saveSelectedCollection } = useCollectionStore();

  const onRowSelection = (event) => {
    let subPath = "";
    const pathSegments = route.path.split("/");
    const idIndex = pathSegments.findIndex(
      (segment) => segment === route.params.id
    );
    if (idIndex >= 0 && idIndex < pathSegments.length - 1) {
      subPath = pathSegments.slice(idIndex + 1).join("/");
    }
    const newPath = `/dashboard/collection/${event.id}${
      subPath ? `/${subPath}` : "/smartlnks"
    }`;

    // Navigate to the new path
    router.push(newPath);
  };

  const findSelectedCollection = () => {
    const collectionId = route.params.id;
    if (collectionId && Array.isArray(data.value)) {
      return data.value.find((collection) => collection.id == collectionId);
    }
    return null;
  };

  const notifyCollectionNotFound = () => {
    notifyError({
      title: "Collection not found!",
      text: "The requested collection could not be found.",
    });
  };

  watchEffect(() => {
    console.log("==> composable watchEffect called");

    if (data.value && route.params.id) {
      const selectedCollection = findSelectedCollection();
      if (selectedCollection) saveSelectedCollection(selectedCollection);
      else notifyCollectionNotFound();
    }
  });

  return {
    collectionTableRef,
    onRowSelection,
  };
};
