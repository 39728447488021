<script setup>
import { useCollectionsTable } from "~/composables/collections-table/useCollectionsTable.js";
import { useCollectionsTableFilters } from "~/composables/collections-table/useCollectionsTableFilters.js";
import { useCollectionStore } from "~/store/useCollectionStore.js";
import { useCollectionApi } from "~/composables/useCollectionApi.js";
import { storeToRefs } from "pinia";

// store
const collectionStore = useCollectionStore();
const { collectionList: data } = storeToRefs(collectionStore);
const { saveSelectedCollection } = collectionStore;

// Table Filters
const { filters, clearFilters } = useCollectionsTableFilters();

// API Calls
const { loading, fetchCollectionsApi } = useCollectionApi();

const { collectionTableRef, onRowSelection } = useCollectionsTable({ data: data });

// Functions
const refreshCollectionsTable = () => fetchCollectionsApi();

provide("refreshCollectionsTable", refreshCollectionsTable);
provide("loadCollections", fetchCollectionsApi);

onMounted(fetchCollectionsApi);
</script>
<template>
  <DataTable
    id="collections-table"
    :ref="collectionTableRef"
    :value="data"
    :paginator="false"
    dataKey="id"
    v-model:filters="filters"
    selectionMode="single"
    class="collections-table h-full"
    :class="loading ? 'relative' : 'unset'"
    :loading="loading"
    :rowHover="true"
    filterDisplay="menu"
    removableSort
    responsiveLayout="scroll"
    :auto-layout="true"
    :resizableColumns="false"
    :reorderableColumns="false"
    :metaKeySelection="true"
    columnResizeMode="expand"
    :scrollHeight="data?.length > 5 ? 'flex' : ''"
    scrollDirection="both"
    :scrollable="true"
    :globalFilterFields="['name']"
    @update:selection="onRowSelection"
  >
    <template #header>
      <CollectionsTableHeader :length="data?.length" :loading="loading" />
    </template>

    <template #empty>
      <CollectionsTableEmptyPlaceholder />
    </template>

    <Column
      field="Collections"
      filterField="name"
      class="rounded-xl !border-none my-0 md:my-2 lg:my-2 !p-0 bg-white dark:bg-[#181818] dark:border-[#181818] dark:text-slate-50"
      sort-field="name"
      header="Collections"
      key="id"
      sortable
    >
      <template #body="slotProps">
        <CollectionsTableTemplatesAndFiltersNameBody :data="slotProps.data" />
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          placeholder="Search by name"
        />
      </template>
    </Column>
  </DataTable>
  <ConfirmDialog></ConfirmDialog>
</template>

<style lang="postcss">
#collections-table .p-datatable-thead th {
  border-radius: 5px;
  @apply hover:rounded-xl;
}
#collections-table .p-column-header-content {
  padding: 0.75rem 0.5rem;
  background: #fafafa;
}
#collections-table .p-datatable-wrapper {
  padding: 0 1rem;
}
#collections-table table {
  height: 100%;
}
#collections-table tbody > tr {
  @apply transition hover:rotate-2;
  /* hover:scale-105 */
}
#collections-table li {
  padding: 0.2rem 1rem !important;
  border-radius: 10px !important;
  margin: 7px 0 !important;
}
#collections-table table {
  border-collapse: separate !important;
  border-spacing: 0 14px;
  border-radius: 50% !important;
  width: 100% !important;
}
</style>
