import { showConfirmationDialog } from "~/utils/showConfirmationDialog.js";

export const showDeleteCollectionConfirmation = ({
  confirm,
  name,
  callback,
}) => {
  const options = {
    message: `Are you sure you want to delete the selected collection i.e "${name}" and all associated links + their stats. This action cannot be undone - please proceed with caution.`,
  };
  showConfirmationDialog(confirm, options, callback);
};
