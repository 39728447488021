<script setup>
import { showCollectionFormDialog } from "~/utils/dialogActions";

const loadCollections = inject("loadCollections");
</script>

<template>
  <button
    class="border p-2 px-3 rounded-lg text-sm font-medium dark:text-gray-50 cursor-pointer select-none"
    @click="
      showCollectionFormDialog({
        selectedCollection: null,
        callback: loadCollections,
      })
    "
  >
    Add Collection
  </button>
</template>

<style></style>
