<script setup>
import { SvgLink, SvgPieChart } from "#components";
import { storeToRefs } from "pinia";
import { useCollectionStore } from "~/store/useCollectionStore.js";

// Store
const useCollection = useCollectionStore();
const { showOverviewBasicTiles } = storeToRefs(useCollection);
const { toggleOverviewBasicTiles } = useCollection;

// Router
const route = useRoute();
const router = useRouter();

// Computed properties
const activeTab = computed(() => route.path.split("/").at(-1));

// Navigation method
const navigate = (path) => router.push(path);

// Tabs list
const tabs = computed(() => [
  {
    name: "Overview",
    route: `/dashboard/collection/${route.params.id}/overview`,
    icon: SvgPieChart,
    path: ["overview"],
  },
  {
    name: "Links",
    route: `/dashboard/collection/${route.params.id}/smartlnks`,
    icon: SvgLink,
    path: ["smartlnks", "smartdomains", "smartpixels"],
  },
]);

// Add Domain route
const addDomainRoute = "/dashboard/settings/domain";
</script>

<template>
  <div
    class="border-b border-gray-200 dark:border-gray-700 flex justify-between items-center"
  >
    <ul
      class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 z-10 select-none"
    >
      <!-- Dynamic Tabs -->
      <li v-for="tab in tabs" :key="tab.name" class="mr-2 z-10">
        <a
          @click="navigate(tab.route)"
          class="inline-flex p-4 rounded-t-lg group cursor-pointer"
          :class="{
            'text-primary dark:text-primary border-primary active dark:border-primary font-semibold border-b-2': tab.path.includes(
              activeTab
            ),
          }"
        >
          <component
            :is="tab.icon"
            :height="18"
            class="w-5 h-5"
            :class="{
              '!text-primary !dark:text-primary': tab.path.includes(activeTab),
              'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300': !tab.path.includes(
                activeTab
              ),
            }"
          />
          <span class="ml-2">{{ tab.name }}</span>
        </a>
      </li>
      <!-- Add Domain Tab -->
      <li class="mr-2 z-10">
        <NuxtLink
          :to="addDomainRoute"
          class="inline-flex p-4 rounded-t-lg group cursor-pointer"
        >
          <i class="pi pi-plus mr-3"></i>
          Add Domain
        </NuxtLink>
      </li>
    </ul>
    <!-- Toggle Button -->
    <Button
      v-if="activeTab === 'overview'"
      :icon="showOverviewBasicTiles ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
      text
      rounded
      :aria-label="showOverviewBasicTiles ? 'Hide basic data' : 'Show basic data'"
      @click="toggleOverviewBasicTiles"
    />
  </div>
</template>

<style></style>
