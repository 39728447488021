<script setup>
const route = useRoute();
</script>
<template>
  <div
    class="grid grid-cols-1 gap-3 h-full grid-auto-rows overflow-hidden select-none"
    :class="{ '!gap-0': !route.params.id }"
  >
    <div class="col-span-1">
      <CollectionLayoutTabs v-if="route.params.id" />
    </div>
    <div class="col-span-1 h-full overflow-hidden z-10">
      <slot />
    </div>
  </div>
</template>

<style></style>
