<template>
  <svg
    class="dark:fill-gray-50"
    width="36"
    height="36"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
    ></path>
    <path d="M7 7h10"></path>
    <path d="M7 12h10"></path>
    <path d="M7 17h6"></path>
  </svg>
</template>
