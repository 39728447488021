import { showCollectionFormDialog } from "~/utils/dialogActions";
import { useCollectionApi } from "~/composables/useCollectionApi";
import { showDeleteCollectionConfirmation } from "~/utils/collectionContextMenuActions.js";

export const useCollectionsTableContextMenu = ({
  confirm,
  refreshCollectionsTable,
}) => {
  if (process.server) return;

  const contextMenuRef = ref();
  const cmSelectedRow = ref();
  const linksApi = useLinksApi();
  const route = useRoute();

  const { pinOrUnpinCollectionApi, deleteCollectionApi } = useCollectionApi();

  const contextMenuItems = ref([
    {
      label: "Options",
      items: [
        {
          key: "pin-unpin",
          label: "Pin",
          icon: "pi pi-pin",
          visible: true,
          command: () =>
            pinOrUnpinCollectionApi({
              collectionId: cmSelectedRow.value?.id,
              callback: refreshCollectionsTable,
            }),
        },
        {
          key: "edit",
          label: "Edit",
          icon: "pi pi-pencil",
          visible: true,
          command: () =>
            showCollectionFormDialog({
              selectedCollection: cmSelectedRow,
              callback: refreshCollectionsTable,
            }),
        },
        // {
        //   key: "copy",
        //   label: "Copy",
        //   icon: "pi pi-copy",
        // },
        // {
        //   key: "qr-code",
        //   label: "QR Code",
        //   icon: "pi pi-qrcode",
        //   visible: true,
        // },
        // {
        //   key: "share",
        //   label: "Share",
        //   icon: "pi pi-share-alt",
        // },
        {
          key: "analytics",
          label: "Analytics",
          icon: "pi pi-chart-pie",
          command: () =>
            navigateTo(
              `/dashboard/collection/${cmSelectedRow.value?.id}/overview`
            ),
        },
        {
          key: "seperator",
          separator: true,
          visible: true,
        },
        // {
        //   key: "reset-clicks",
        //   label: "Reset Clicks",
        //   icon: "pi pi-replay",
        // },
        {
          key: "delete",
          label: "Delete",
          icon: "pi pi-trash",
          visible: true,
          class: "danger-menu-item",
          command: () =>
            showDeleteCollectionConfirmation({
              confirm: confirm,
              name: cmSelectedRow.value?.name,
              callback: () => {
                deleteCollectionApi({
                  collectionId: cmSelectedRow.value?.id,
                  callback: refreshCollectionsTable,
                });

                if (route.params.id == cmSelectedRow.value?.id) {
                  navigateTo("/dashboard/collection");
                }
              },
            }),
        },
      ],
    },
  ]);

  const onRowContextMenu = (event, data) => {
    handleContextMenuRowSelection(data);
    contextMenuRef.value.toggle(event);
  };

  const handleContextMenuRowSelection = (data) => {
    cmSelectedRow.value = data;
    updatePinLabelAndIcon();
  };

  const updatePinLabelAndIcon = () => {
    const pinItem = contextMenuItems.value[0]?.items.find(
      (item) => item.key === "pin-unpin"
    );
    const isPinned = cmSelectedRow.value?.is_pinned;
    pinItem.label = isPinned ? "Unpin" : "Pin";
    pinItem.icon = isPinned ? "pi-unpin" : "pi-pin";
  };

  watch(cmSelectedRow, () => {
    const pinUnpinMenuItem = contextMenuItems.value[0]?.items.find(
      (item) => item.key === "pin-unpin"
    );
    const deleteMenuItem = contextMenuItems.value[0]?.items.find(
      (item) => item.key === "delete"
    );
    const seperatorMenuItem = contextMenuItems.value[0]?.items.find(
      (item) => item.key === "seperator"
    );
    const isVisible = !cmSelectedRow.value?.is_premium;
    pinUnpinMenuItem.visible = isVisible;
    deleteMenuItem.visible = isVisible;
    seperatorMenuItem.visible = isVisible;
  });

  return {
    contextMenuItems,
    cmSelectedRow,
    contextMenuRef,
    onRowContextMenu,
  };
};
