import { FilterMatchMode, FilterOperator } from "primevue/api";

const generateFilter = (matchMode, operator = null) => {
  if (operator) {
    return {
      operator,
      constraints: [{ value: null, matchMode }],
    };
  }
  return {
    value: null,
    matchMode,
  };
};

export const useCollectionsTableFilters = () => {
  const emptyFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: generateFilter(FilterMatchMode.CONTAINS, FilterOperator.AND),
  };

  const filters = ref(emptyFilters);

  const initFilters = () => {
    filters.value = emptyFilters;
  };

  const clearFilters = () => {
    initFilters();
  };

  return { filters, clearFilters, initFilters };
};
